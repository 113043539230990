import type { SetOptional } from 'type-fest'
import { type Component, reactive, ref, watch } from 'vue'
import { useId } from './useId'

type ToastType = 'success' | 'error' | 'info' | 'warning'

export enum ToastState {
  Created = 0,
  Entering = 1,
  Entered = 2,
  Elapsing = 3,
  Paused = 4,
  Exiting = 5,
  Exited = 6,
}

type BaseToastAction = {
  type: 'link' | 'button'
  action: string | (() => void | Promise<void>)
  label: string
  variant?: 'primary' | 'secondary' | 'tertiary'
  icon?: Component
}

export type ToastAction = BaseToastAction &
  (
    | {
        type: 'link'
        action: string
      }
    | {
        type: 'button'
        action: () => void | Promise<void>
      }
  )

export type Toast = {
  id: string
  type: ToastType
  title: string
  description?: string
  actions?: ToastAction[]

  duration: number
  timeout: number | null
  state: ToastState

  getTransitionProps: () => Record<string, () => void>
  setState: (state: ToastState) => void
  onClose?: () => void | (() => Promise<void>)
  resume: () => void
  pause: () => void
  dismiss: () => void
}

export type ToastCreateOptions = SetOptional<Pick<Toast, 'id' | 'type' | 'title' | 'description' | 'duration' | 'actions'>, 'id' | 'description' | 'duration'>

const toasts = ref<Toast[]>([])

export function useToast() {
  const api = reactive({
    toasts,
    create(options: ToastCreateOptions) {
      const { id = `ptt-toast-${useId()}`, duration = 5000, ...rest } = options
      // We add a delay to the duration to allow the toast transition to finish gracefully before being removed
      const delay = 250

      function createTimeout() {
        toast.timeout = window.setTimeout(() => {
          api.remove(id)
        }, duration + delay)
      }

      const toast: Toast = reactive({
        ...rest,
        id,
        duration,
        timeout: null,
        state: ToastState.Created,
        setState(state: ToastState) {
          toast.state = state
        },
        resume() {
          toast.setState(ToastState.Elapsing)
        },
        pause() {
          toast.setState(ToastState.Paused)
        },
        dismiss() {
          if (toast.timeout) {
            window.clearTimeout(toast.timeout)
          }

          api.remove(id)
        },
        getTransitionProps() {
          return {
            onEnter: () => {
              toast.setState(ToastState.Entering)
            },
            onAfterAppear: () => {
              toast.setState(ToastState.Entered)
            },
            onLeave: () => {
              toast.setState(ToastState.Exiting)
            },
            onAfterLeave: () => {
              toast.setState(ToastState.Exited)
            },
          }
        },
      })

      watch(
        () => toast.state,
        (state) => {
          if (state === ToastState.Entered) {
            createTimeout()
          }

          if (state === ToastState.Paused && toast.timeout) {
            window.clearTimeout(toast.timeout)
          }

          if (state === ToastState.Elapsing) {
            createTimeout()
          }

          if (state === ToastState.Exited) {
            toast.dismiss()
          }
        }
      )

      toasts.value.push(toast)
    },
    remove(id: string) {
      const index = toasts.value.findIndex((toast) => toast.id === id)
      if (index === -1) return

      const toast = toasts.value[index]
      if (toast.onClose) {
        toast.onClose()
      }

      toasts.value.splice(index, 1)
    },
  })

  return {
    api,
  }
}
